import { useState, useEffect } from 'preact/hooks';
import { PublicApi } from '../../../types/player-api-types.ts';
import { useWistiaPlayerContext } from './useWistiaPlayerContext.tsx';

export const useWistiaPlayerNotIframeApiProxy = (): PublicApi | null => {
  const { player } = useWistiaPlayerContext();

  const [playerNotIframeApiProxy, setPlayerNotIframeApiProxy] = useState<PublicApi | null>(null);

  useEffect(() => {
    if (player.iframe == null) {
      setPlayerNotIframeApiProxy(player);
      return;
    }

    player.hasData(() => {
      const handle = player.iframe?.id != null ? window.Wistia?.api(player.iframe.id) : player;
      if (handle == null) {
        return;
      }

      setPlayerNotIframeApiProxy(handle);
    });
  }, [player]);

  return playerNotIframeApiProxy;
};

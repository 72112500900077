import { useEffect, useState } from 'preact/hooks';
import { CueConfig } from '../types.ts';
import { useRemapTime } from './useRemapTime.ts';
import { useWistiaPlayerNotIframeApiProxy } from './useWistiaPlayerNotIframeApiProxy.ts';

export const useLocalizedCaptionsData = (unlocalizedCaptionsData: CueConfig[]): CueConfig[] => {
  const player = useWistiaPlayerNotIframeApiProxy();

  const remapTime = useRemapTime();

  const [localizedCaptionsData, setLocalizedCaptionsData] =
    useState<CueConfig[]>(unlocalizedCaptionsData);

  useEffect(() => {
    if (player == null) {
      return;
    }

    const sourceLanguage = player.languages().find((lang) => lang.sourceLanguage);
    const playerLanguage = player.language();
    if (sourceLanguage == null || playerLanguage == null) {
      return;
    }

    const newCaptionsData = unlocalizedCaptionsData.map((cue) => {
      const startTime =
        remapTime(sourceLanguage.ietfLanguageTag, playerLanguage.ietfLanguageTag, cue[0] / 1000) *
        1000;
      const endTime =
        remapTime(sourceLanguage.ietfLanguageTag, playerLanguage.ietfLanguageTag, cue[1] / 1000) *
        1000;
      return [startTime, endTime, cue[2]] as CueConfig;
    });
    setLocalizedCaptionsData(newCaptionsData);
  }, [player, unlocalizedCaptionsData, player?.hashedId(), remapTime]);

  return localizedCaptionsData.length > 0 ? localizedCaptionsData : unlocalizedCaptionsData;
};

import { useEffect, useState } from 'preact/hooks';
import { isNil } from '@wistia/type-guards';
import { PublicApi } from '../../../types/player-api-types.ts';
import { onFindApiHandle } from '../../../utilities/onFindApiHandle.ts';

export const useWistiaPlayer = (matcher?: string): PublicApi | null => {
  const [player, setPlayer] = useState<PublicApi | null>(null);

  useEffect(() => {
    if (isNil(matcher)) {
      return;
    }
    onFindApiHandle({ matcher, functionToRun: setPlayer });
  }, []);

  return player;
};

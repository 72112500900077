import { ComponentChildren, Fragment, h, JSX } from 'preact';
import { memo } from 'preact/compat';
import type { SlateNode, TranscriptDocument } from '../types.ts';
import { TimedWord } from './TimedWord.tsx';
import { Chapter } from './Chapter.tsx';
import { ExtendedAudioDescriptionCue } from './ExtendedAudioDescriptionCue.tsx';
import { Paragraph } from './Paragraph.tsx';
import { FocusScrollManager } from './FocusScrollManager.tsx';
import { useTranscriptSignals } from '../hooks/useTranscriptSignals.tsx';
import { Cue } from './Cue.tsx';

type TranscriptDocumentRendererProps = {
  transcriptDocument: TranscriptDocument;
};

type TranscriptDocumentElementProps = {
  children: ComponentChildren;
  element: SlateNode;
};

const TranscriptDocumentElement = ({
  element,
  children,
}: TranscriptDocumentElementProps): JSX.Element => {
  switch (element.type) {
    case 'timed-words':
      return (
        <Fragment>
          {element.words.map(([word, startTime], index, elements) => (
            <Fragment>
              <TimedWord
                startTime={startTime}
                word={word}
                isLastWord={index === elements.length - 1}
              />
            </Fragment>
          ))}
        </Fragment>
      );
    case 'cue':
      return (
        <Cue startTimeMs={element.startTimeMs} endTimeMs={element.endTimeMs}>
          {children}
        </Cue>
      );
    case 'chapter':
      return (
        <FocusScrollManager>
          <Chapter
            id={element.chapterId}
            chapterTitle={element.children?.[0].text}
            time={element.chapterTime}
          />
        </FocusScrollManager>
      );
    case 'extended-audio-description-cue':
      return (
        <FocusScrollManager>
          <ExtendedAudioDescriptionCue
            text={element.children[0].words[0][0]}
            startTimeMs={element.triggerStart}
          />
        </FocusScrollManager>
      );
    case 'paragraph':
    case undefined:
    default:
      return (
        <FocusScrollManager>
          <Paragraph>{children}</Paragraph>
        </FocusScrollManager>
      );
  }
};

const TranscriptDocumentBranch = ({ element }: { element: SlateNode }): JSX.Element => {
  return (
    <TranscriptDocumentElement element={element}>
      {element.children?.map((chlidElement) => <TranscriptDocumentBranch element={chlidElement} />)}
    </TranscriptDocumentElement>
  );
};

const TranscriptDocumentRenderer = ({
  transcriptDocument,
}: TranscriptDocumentRendererProps): JSX.Element => {
  const { currentWordElement, timedWordElementsInCurrentCue } = useTranscriptSignals();
  currentWordElement.value = null;
  timedWordElementsInCurrentCue.value = [];

  return (
    <Fragment>
      {transcriptDocument.map((element) => (
        <TranscriptDocumentBranch element={element} />
      ))}
    </Fragment>
  );
};

export const MemoizedTranscriptDocumentRenderer = memo(TranscriptDocumentRenderer);

import { useEffect, useState } from 'preact/hooks';
import { useWistiaPlayerContext } from './useWistiaPlayerContext.tsx';

export const useRemapTime = (): ((
  fromLanguage: string,
  toLanguage: string,
  fromTime: number,
) => number) => {
  const { player } = useWistiaPlayerContext();

  const [remapTime, setRemapTime] = useState<
    (fromLanguage: string, toLanguage: string, fromTime: number) => number
  >(() => {
    return (_fromLanguage: string, _toLanguage: string, fromTime: number) => {
      return fromTime;
    };
  });

  useEffect(() => {
    // No need to remap time if there's only one language.
    if (player.languages().length <= 1) {
      setRemapTime(() => {
        return (_fromLanguage: string, _toLanguage: string, fromTime: number) => {
          return fromTime;
        };
      });
    }

    void player.getRemapTime().then((fn) => {
      setRemapTime(() => {
        return (fromLanguage: string, toLanguage: string, fromTime: number) => {
          return fn(fromLanguage, toLanguage, fromTime);
        };
      });
    });
  }, [player]);

  return remapTime;
};
